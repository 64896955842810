import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  trimDate,
  floorDay,
  ceilDay,
  getLaunchDate
} from './dateUtil';

var selfUrl;
if(process.env.NODE_ENV === "production") {
  selfUrl = 'https://hackdaily.herokuapp.com/';
} else if(process.env.NODE_ENV === "development") {
  selfUrl = 'http://localhost:3000/';
}

const TableStyle = styled.table`
  border: 1px solid black;
  margin: 15px;
`;
const ThStyle = styled.th`
  border: 1px solid black;
  padding: 10px;
`;
const TdStyle = styled.td`
  border: 1px solid black;
  padding: 10px;
`;

function ProblemList(props) {
  const [problems, setProblems] = useState(null);
  useEffect(() => {
    if(problems === null) {
      fetch(`/api/get_raw_problem_list`)
          .then(response => response.json())
          .then(function (params) {
            if(params.success) {
              setProblems(params.problems);
            } else {
              console.log(params);
            }
          });
    }
  }, []);
  var problemList = [];
  var key_count = 0;
  for(var idx in problems) {
    const problem = problems[idx];
    const text_parts = problem.description.split('\r\n')
    var description = [];
    for(var idx in text_parts) {
      if(idx >= 14) {break;}
      description.push(
      <React.Fragment key={key_count}>
        {text_parts[idx]}<br/>
      </React.Fragment>);
      key_count += 1;
    }
    problemList.push(<tr key={key_count}>
      <TdStyle>{problem.problem_id}</TdStyle>
      <TdStyle>
        <div style={{'fontSize': '0.8em'}}>
        {description}
        </div>
      </TdStyle>
      <TdStyle>{problem.level}</TdStyle>
      <TdStyle>{problem.ask_on.split('T')[0]}</TdStyle>
      <TdStyle><a href={`/dashboard/edit_problem?problem_id=${problem.problem_id}`}>
        edit_problem
        </a>
      </TdStyle>
    </tr>);
    key_count += 1;
  }
  return (<>
    <div style={{margin: '15px'}}>
    <a href={`/dashboard/add_problem`}>
      add_new_problem
    </a>
    <h1>
      Problems
    </h1>
    Total problems: {problems ? problems.length : 0}
    <TableStyle>
      <thead>
      <tr>
        <ThStyle>problem_id</ThStyle>
        <ThStyle style={{'minWidth': '500px'}}>description</ThStyle>
        <ThStyle>level</ThStyle>
        <ThStyle>ask_on</ThStyle>
        <ThStyle>edit</ThStyle>
      </tr>
      </thead>
      <tbody>
      {problemList}
      </tbody>
    </TableStyle>
    </div>
  </>)
};

function DaysList(props) {
  const [problems, setProblems] = useState(null);
  useEffect(() => {
    if(problems === null) {
      fetch(`/api/get_raw_problem_list`)
          .then(response => response.json())
          .then(function (params) {
            if(params.success) {
              setProblems(params.problems);
            } else {
              console.log(params);
            }
          });
    }
  }, []);
  var unreadyProblems = [];
  var readyDays = {};
  var latestDay = getLaunchDate();
  var earliestDay = getLaunchDate();
  for(var idx in problems) {
    var problem = problems[idx];
    if(problem.metadata.includes('[DAY]')) {
      var trimmedDay = problem.ask_on.split('T')[0];
      if(!(trimmedDay in readyDays)) {
        readyDays[trimmedDay] = [];
      }
      readyDays[trimmedDay].push(problem);
      if(floorDay(latestDay).getTime() <
        ceilDay(new Date(problem.ask_on)).getTime()) {
        latestDay = new Date(problem.ask_on);
      }
      if(ceilDay(earliestDay).getTime() >
        floorDay(new Date(problem.ask_on)).getTime()) {
        earliestDay = floorDay(new Date(problem.ask_on));
      }
    } else {
      unreadyProblems.push(problem);
    }
  }
  var currentDay = earliestDay;
  var dayList = [];
  var key_count = 0;
  function getProblemDescription(problem, levelValue) {
    if(levelValue !== 'None') {
      levelValue.push(
        <React.Fragment key={key_count}>
          <b><a href={`/dashboard/edit_problem?problem_id=${problem.problem_id}`}>
            CONFLICTION_PROBLEM_{problem.problem_id}</a></b><br/>
        </React.Fragment>);
      key_count += 1;
      return levelValue;
    }
    var text_parts = problem.description.split('\r\n')
    var description = [];
    description.push(
      <React.Fragment key={key_count}>
        <b><a href={`/dashboard/edit_problem?problem_id=${problem.problem_id}`}>
          problem_{problem.problem_id}</a></b><br/>
      </React.Fragment>);
    key_count += 1;
    if(!props.noDescription) {
      for(var idx in text_parts) {
        description.push(
        <React.Fragment key={key_count}>
          {text_parts[idx]}<br/>
        </React.Fragment>);
        key_count += 1;
      }
    }
    for(var val of problem.metadata.split('\r\n')) {
      if(val.includes('[TAG]')) {
        description.push(
        <React.Fragment key={key_count}>
          <b>{val}</b><br/>
        </React.Fragment>);
        key_count += 1;
      }
    }
    description.push(
      <React.Fragment key={key_count}>
        <b>Answer: {problem.answer}</b><br/>
      </React.Fragment>);
    key_count += 1;
    return description;
  }
  function updateIfNoneWithAddProblem(levelValue, trimmedDay, level) {
    if(levelValue !== 'None') {
      return levelValue
    }
    return (
      <a href={`/dashboard/add_problem?ask_on=${trimmedDay}&level=${level}`}>
        add_problem
      </a>
    )
  }
  latestDay.setDate(latestDay.getDate()+7);
  while(floorDay(currentDay).getTime() < ceilDay(latestDay).getTime()) {
    var trimmedDay = trimDate(currentDay);
    var easy = 'None';
    var medium = 'None';
    var hard = 'None';
    if(readyDays[trimmedDay]) {
      for(var problem of readyDays[trimmedDay]) {
        if(problem.level === 'easy') {
          easy = getProblemDescription(problem, easy);
        } else if(problem.level === 'medium') {
          medium = getProblemDescription(problem, medium);
        } else if(problem.level === 'hard') {
          hard = getProblemDescription(problem, hard);
        }
      }
    }
    easy = updateIfNoneWithAddProblem(easy, trimmedDay, 'easy');
    medium = updateIfNoneWithAddProblem(medium, trimmedDay, 'medium');
    hard = updateIfNoneWithAddProblem(hard, trimmedDay, 'hard');
    if(trimmedDay == trimDate(getLaunchDate())) {
      dayList.push(<tr key='launchDate'>
        <TdStyle>{trimmedDay}<br/>(launch date)</TdStyle>
      </tr>);  
    }
    if(trimmedDay == trimDate(new Date())) {
      dayList.push(<tr key='currentDate'>
        <TdStyle>{trimmedDay}<br/>(current date)</TdStyle>
      </tr>);  
    }
    dayList.push(<tr key={key_count}>
      <TdStyle><a href={`/old_problems?date=${trimmedDay}`}>{trimmedDay}</a></TdStyle>
      <TdStyle><div style={{'fontSize': '0.8em'}}>{easy} </div></TdStyle>
      <TdStyle><div style={{'fontSize': '0.8em'}}>{medium} </div></TdStyle>
      <TdStyle><div style={{'fontSize': '0.8em'}}>{hard} </div></TdStyle>
    </tr>);
    key_count += 1;
    currentDay.setDate(currentDay.getDate()+1);
  }

  var problemList = [];
  for(var idx in unreadyProblems) {
    const problem = unreadyProblems[idx];
    const text_parts = problem.description.split('\r\n')
    var description = [];
    for(var idx in text_parts) {
      if(idx >= 14) {break;}
      description.push(
      <React.Fragment key={key_count}>
        {text_parts[idx]}<br/>
      </React.Fragment>);
      key_count += 1;
    }
    problemList.push(<tr key={key_count}>
      <TdStyle>{problem.problem_id}</TdStyle>
      <TdStyle>
        <div style={{'fontSize': '0.8em'}}>
        {description}
        </div>
      </TdStyle>
      <TdStyle>{problem.level}</TdStyle>
      <TdStyle>{problem.ask_on.split('T')[0]}</TdStyle>
      <TdStyle><a href={`/dashboard/edit_problem?problem_id=${problem.problem_id}`}>
        edit_problem
        </a>
      </TdStyle>
    </tr>);
    key_count += 1;
  }
  return (<>
    <div style={{margin: '15px'}}>
    <a href={`/dashboard/add_problem`}>
      add_new_problem
    </a>
    <h1>
      Days
    </h1>
    <TableStyle>
      <thead>
      <tr>
        <ThStyle style={{'minWidth': '100px'}}>ask_on</ThStyle>
        <ThStyle style={{'minWidth': '300px'}}>easy</ThStyle>
        <ThStyle style={{'minWidth': '300px'}}>medium</ThStyle>
        <ThStyle style={{'minWidth': '300px'}}>hard</ThStyle>
      </tr>
      </thead>
      <tbody>
      {dayList}
      </tbody>
    </TableStyle>
    <h1>
      Problems
    </h1>
    Total problems: {unreadyProblems ? unreadyProblems.length : 0}
    <TableStyle>
      <thead>
      <tr>
        <ThStyle>problem_id</ThStyle>
        <ThStyle style={{'minWidth': '500px'}}>description</ThStyle>
        <ThStyle>level</ThStyle>
        <ThStyle>ask_on</ThStyle>
        <ThStyle>edit</ThStyle>
      </tr>
      </thead>
      <tbody>
      {problemList}
      </tbody>
    </TableStyle>
    </div>
  </>)
};


function AddProblem(props) {
  var default_problem_id = "";
  var default_ask_on = "2023-01-08";
  var default_text = "";
  var default_level = "easy";
  var default_question_type = "multiple_choice";
  var default_answer = "";
  var default_source = "";
  var default_metadata = "";
  if(props.problem) {
    default_ask_on = props.problem.ask_on.split('T')[0];
    default_text = props.problem.description;
    default_level = props.problem.level;
    default_question_type = props.problem.question_type;
    default_answer = props.problem.answer;
    default_source = props.problem.source;
    default_metadata = props.problem.metadata;
    default_problem_id = props.problem.problem_id;
  } else if(props.url) {
    const urlParams = new URLSearchParams(props.url.split('?')[1]);
    if(urlParams.get('ask_on')) {
      default_ask_on = urlParams.get('ask_on');
      default_metadata = '[DAY]\n';
      default_level = urlParams.get('level');
    }
  }
  return (
    <>
    <h1>Add Problem.</h1>
    <form action="/api/add_new_problem" method="post">
      <label>Ask date:</label>
      <input type="date" id="ask_on" name="ask_on" defaultValue={default_ask_on} /> <br/>
      <label>Problem Description:</label><br/>
      <textarea name="description" cols="80" rows="25">{default_text}</textarea><br/>
      <label>Difficulty Level:</label>
      <select id="level" name="level" defaultValue={default_level}>
        <option value="easy">Easy</option>
        <option value="medium">Medium</option>
        <option value="hard">Hard</option>
      </select><br/>
      <label>Question type:</label>
      <select id="question_type" name="question_type" defaultValue={default_question_type}>
        <option value="multiple_choice">Multiple Choice</option>
        <option value="string">String</option>
      </select><br/>
      <label>Answer:</label>
      <input type="text" id="answer" name="answer" defaultValue={default_answer}/> <br/>
      <label>Source:</label>
      <input type="text" id="source" name="source" defaultValue={default_source}/> <br/>
      <label>Metadata:</label><br/>
      <textarea name="metadata" cols="80" rows="5">{default_metadata}</textarea><br/>
      <button type="submit" name="submit">Submit</button>
      <input id="problem_id" name="problem_id" style={{visibility: 'hidden'}} defaultValue={default_problem_id} /> <br/>
    </form>
    </>
  );
}

function EditProblem(props) {
  const [currentProblem, setCurrentProblem] = useState(null);
  const urlParams = new URLSearchParams(props.url.split('?')[1]);
  useEffect(() => {
      fetch(`/api/get_raw_problem?problem_id=${urlParams.get('problem_id')}`)
          .then(response => response.json())
          .then(params => {
            if(params.success) {
              setCurrentProblem(params.problem);
            } else {
              console.log(params);
            }
          });
  }, []);
  return (<>{currentProblem ? <AddProblem problem={currentProblem}/> : <></>}</>);
}

function ShiftAllProblems(props) {
  return (<>
    <b>Be Careful!!</b><br/><br/>
    <form action="/api/shift_all_problems" method="post">
      <select id="level" name="level" defaultValue='all'>
        <option value="all">All</option>
        <option value="easy">Easy</option>
        <option value="medium">Medium</option>
        <option value="hard">Hard</option>
      </select><br/><br/>
      Enter postive or negative integers:<br/>
      <input id="shift_days_count" name="shift_days_count"/><br/><br/>
      <button type="submit" name="submit">Shift All Problems!!!</button>
    </form></>
  )
}

function EditUserData(props) {
  const [currentUser, setCurrentUser] = useState(null);
  const urlParams = new URLSearchParams(props.url.split('?')[1]);
  const user_id = urlParams.get('user_id');
  function formatProblems(problems) {
    var rows = [];
    if(problems.empty_problem_list) { return rows; }
    for(var idx in problems) {
      rows.push(
        <React.Fragment key={idx}>
          <a href={`/dashboard/edit_problem?problem_id=${idx}`}>
            problem_{idx}</a><br/>
          userAnswer: {problems[idx].userAnswer} <br/>
          correctAnswer: {problems[idx].correctAnswer} <br/>
          <br/>
        </React.Fragment>
      )
    }
    return rows
  }
  useEffect(() => {
    if(!user_id) { return; }
    fetch(`/api/get_raw_user_data?user_id=${user_id}`)
        .then(response => response.json())
        .then(params => {
          if(params.success) {
            params.userProfile.last_login = new Date(
              params.userProfile.last_login);
            setCurrentUser(params.userProfile);
          } else {
            console.log(params);
          }
        });
  }, []);
  return (<>
  {currentUser ? <>
    <h1>
      User profile:
    </h1>
    <TableStyle>
      <tbody>
      <tr key='user_id'>
        <TdStyle>user_id</TdStyle>
        <TdStyle>{currentUser.user_id}</TdStyle>
      </tr>
      <tr key='email'>
        <TdStyle>email</TdStyle>
        <TdStyle>{currentUser.email}</TdStyle>
      </tr>
      <tr key='name'>
        <TdStyle>name</TdStyle>
        <TdStyle>{currentUser.name}</TdStyle>
      </tr>
      <tr key='family_name'>
        <TdStyle>family_name</TdStyle>
        <TdStyle>{currentUser.family_name}</TdStyle>
      </tr>
      <tr key='given_name'>
        <TdStyle>given_name</TdStyle>
        <TdStyle>{currentUser.given_name}</TdStyle>
      </tr>
      <tr key='image_url'>
        <TdStyle>image_url</TdStyle>
        <TdStyle><a href={currentUser.image_url}>image url</a></TdStyle>
      </tr>
      <tr key='last_login'>
        <TdStyle>last_login</TdStyle>
        <TdStyle>
          {currentUser.last_login.toDateString()}&nbsp;/&nbsp;
          {currentUser.last_login.getHours()}:{currentUser.last_login.getMinutes()}
        </TdStyle>
      </tr>
      </tbody>
    </TableStyle>
    <form action="/api/edit_user_data" method="post">
      <label><b>Clear all problems:</b> Are you insane?</label>&nbsp;
      <select
        id="clear_all_problems"
        name="clear_all_problems"
        defaultValue='no'>
        <option value="no">No</option>
        <option value="yes">Yes</option>
      </select>&nbsp;
      <button type="submit" name="submit">Submit</button>
      <input
        id="user_id"
        name="user_id"
        style={{visibility: 'hidden'}}
        defaultValue={currentUser.user_id}/><br/>
    </form>
    <TableStyle>
      <tbody>
      <tr key='problems_solved'>
        <TdStyle>problems_solved</TdStyle>
        <TdStyle>{formatProblems(currentUser.problems_solved)}</TdStyle>
      </tr>
      <tr key='problems_missed'>
        <TdStyle>problems_missed</TdStyle>
        <TdStyle>{formatProblems(currentUser.problems_missed)}</TdStyle>
      </tr>
      <tr key='late_problems_solved'>
        <TdStyle>late_problems_solved</TdStyle>
        <TdStyle>{formatProblems(currentUser.late_problems_solved)}</TdStyle>
      </tr>
      <tr key='late_problems_missed'>
        <TdStyle>late_problems_missed</TdStyle>
        <TdStyle>{formatProblems(currentUser.late_problems_missed)}</TdStyle>
      </tr>
      </tbody>
    </TableStyle>
  </> : <>User id not set.</>}
  </>);
}

function Dashboard(props) {
  const [isAdmin, setIsAdmin] = useState(false);
  const currentURL = window.location.href;
  const pathname = new URL(currentURL).pathname.slice('/dashboard'.length);
  var mainContent = null;
  useEffect(() => {
      fetch('/api/check_admin')
        .then(response => response.json())
        .then((params) => {setIsAdmin(params.success)});
  }, []);
  if(pathname === '/problem_list') {
    mainContent = (<><ProblemList/></>);
  } else if(pathname === '/days_list') {
    mainContent = (<><DaysList/></>);
  } else if(pathname === '/tag_view') {
    mainContent = (<><DaysList noDescription={true}/></>);
  } else if(pathname === '/add_problem') {
    mainContent = (<><AddProblem  url={currentURL}/></>);
  } else if(pathname.startsWith('/edit_problem')) {
    mainContent = (<><EditProblem url={currentURL}/></>);
  } else if(pathname.startsWith('/shift_all_problems')) {
    mainContent = (<><ShiftAllProblems url={currentURL}/></>);
  } else if(pathname.startsWith('/edit_user_data')) {
    mainContent = (<><EditUserData url={currentURL}/></>);
  } else {
    mainContent = (<>
    <a href="/dashboard/shift_all_problems">shift all problems</a><br/><br/>
    <a href="/dashboard/edit_user_data">edit user data</a><br/><br/>
    <a href="/dashboard/problem_list">problem list</a><br/><br/>
    <a href="/dashboard/days_list">days list</a><br/><br/>
    <a href="/dashboard/tag_view">tag view</a><br/><br/>
    </>);
  }
  return (
    <>{isAdmin ? mainContent : null}</>
  );
}

export default Dashboard;