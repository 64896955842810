import React from "react";
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { QuestionBackgroundColors } from "./styles.js";

export function parseQuestionText(params) {
  const {
    description,
    userAnswer,
    correctAnswer,
    correctness,
    CurrentStyle } = params;
  const tags = ['<code>', '</code>', '<bullet>', '</bullet>', '<b>', '</b>'];
  function _splitText(curStr, splitList) {
    var result = [];
    if(splitList.length == 0) {
      if(curStr == '') {
        return result;
      } else {
        return [curStr];
      }
    }
    var tempArr =  curStr.split(
      splitList[splitList.length-1]);
    for(var idx in tempArr) {
      var val = tempArr[idx];
      var childSplitList = [...splitList];
      childSplitList.pop();
      var returnVals = _splitText(val, childSplitList);
      result = result.concat(returnVals);
      if(Number(idx) !== Number(tempArr.length-1)) {
        result.push(splitList[splitList.length-1]);
      }
    }
    return result;
  }
  const rawSplits = _splitText(description, tags);
  var codeFlag = false, bulletFlag = false, boldFlag = false;
  var buttonCount = 0;
  var result = [];
  function updateDescription(value) {
    result.push(
      <React.Fragment key={result.length}>
        {value}
      </React.Fragment>);
  }
  for(var val of rawSplits) {
    if(val === '<code>') {
      codeFlag = true;
    } else if(val === '</code>') {
      codeFlag = false;
    } else if(val === '<bullet>') {
      bulletFlag = true;
      buttonCount += 1;
      if(Number(userAnswer) === Number(buttonCount)) {
        updateDescription(
          <CurrentStyle.SelectedRadioButton
            type='radio'
            value={buttonCount}
            name='answer'/>);
      } else if(Number(correctAnswer) === Number(buttonCount)) {
        updateDescription(
          <CurrentStyle.CorrectRadioButton
            type='radio'
            value={buttonCount}
            name='answer'/>);
      } else {
        updateDescription(
          <CurrentStyle.RadioButton
            type='radio'
            value={buttonCount}
            name='answer'/>);
      }
    } else if(val === '</bullet>') {
      bulletFlag = false;
    } else if(val === '<b>') {
      boldFlag = true;
    } else if(val === '</b>') {
      boldFlag = false;
    } else {
      if(codeFlag) {
        var newDocco = {...docco};
        if(correctness === true) {
          newDocco['hljs']['background'] = QuestionBackgroundColors.correct;
        } else if(correctness === false) {
          newDocco['hljs']['background'] = QuestionBackgroundColors.incorrect;
        } else {
          newDocco['hljs']['background'] = QuestionBackgroundColors.unsolved;
        }
        var maxLineLen = 0;
        for(var line of val.split('\r\n')) {
          maxLineLen = Math.max(line.length, maxLineLen);
        }
        updateDescription(
          <CurrentStyle.CodeText maxLineLen={maxLineLen}>
          <SyntaxHighlighter language="python" style={newDocco}>
            {val.trim()}
          </SyntaxHighlighter>
          </CurrentStyle.CodeText>);
      } else if(bulletFlag) {
        // boldFlag splits line. Fix this in future.
        for(var line of val.split('\r\n')) {
          updateDescription(
            <CurrentStyle.ChoiceText>
              {boldFlag ? <b>{line}</b> : line}
            </CurrentStyle.ChoiceText>);
        }
      } else {
        // boldFlag splits line. Fix this in future.
        for(var line of val.split('\r\n')) {
          updateDescription(
            <div style={{clear:'both'}}>
              {boldFlag ? <b>{line}</b> : line}
            </div>);
        }
      }
    }
  }
  return result;
}