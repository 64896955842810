import React, { useState } from "react";
import {
  WebFeedbackSectionStyle,
  MobileFeedbackSectionStyle,
} from "./styles.js";

var selfUrl;
var clientId;
if(process.env.NODE_ENV === "production") {
  clientId = '585480318348-f6kf63ecq4rorpuk7f88qialtnhi8nij.apps.googleusercontent.com';
  selfUrl = 'https://hackdaily.herokuapp.com/';
} else if(process.env.NODE_ENV === "development") {
  clientId = '585480318348-o3rofohc7eha4e4oru7kqjilghoqd9nc.apps.googleusercontent.com';
  selfUrl = 'http://localhost:3000/';
}

function FeedbackSection(props) {
  const [copyFeedback, setCopyFeedback] = useState(
    'copy email to clipboard');
  const [copyProblems, setCopyProblems] = useState(
    'copy email to clipboard');
  const [feedback, setFeedback] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [questionLevel, setQuestionLevel] = useState('easy');
  var CurrentStyle;
  if(props.isMobile) {
    CurrentStyle = MobileFeedbackSectionStyle;
  } else {
    CurrentStyle = WebFeedbackSectionStyle;
  }
  function copyLink(target, copyText) {
    navigator.clipboard.writeText(copyText);
    var setFunc = null;
    if(target == 'feedback') {  
      setFunc = setCopyFeedback;
    } else if(target == 'problems') {  
      setFunc = setCopyProblems;
    }
    setFunc('copied to clipboard');
    setTimeout(() => {
      setFunc('copy email to clipboard');
    }, 1000);
  }
  function submitFeedback() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({feedback: feedback})
    };
    console.log('feedback');
    fetch('/api/submit_feedback', requestOptions)
      .then(response => response.json())
      .then(() => {
        setFeedback('');
        props.setMainPopup('feedback_submitted');
      });
  }
  function changeFeedback(e) {
    setFeedback(e.target.value);
  }
  function submitQuestion() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        questionText: questionText,
        questionLevel: questionLevel
      })
    };
    fetch('/api/submit_question_suggestion', requestOptions)
      .then(response => response.json())
      .then(() => {
        setQuestionLevel('easy');
        setQuestionText('');
        props.setMainPopup('question_suggestion_submitted');
      });
  }
  function changeQuestionText(e) {
    setQuestionText(e.target.value);
  }
  function changeQuestionLevel(e) {
    setQuestionLevel(e.target.value);
  }
  return (
    <CurrentStyle.Wrapper>
      <CurrentStyle.Items>
        <CurrentStyle.Item>
        <CurrentStyle.Headline>
        About Us
        </CurrentStyle.Headline>
        <div style={{textAlign: 'center'}}>
        (Oops.. Maybe about me instead of about us?)<br/><br/>
        </div>
        <CurrentStyle.Image src="/about_us_2.jpg" alt="image"/>
        <div style={{wordWrap: 'break-word', width: '100%'}}>
        I am a software engineer at a FAANG company.<br/><br/>
        This is my side project.<br/><br/>
        It can be difficult to practice for interviews when you are employed.<br/><br/>
        <b>Layoffs</b> are unpleasant!<br/><br/>
        There have been a lot of layoffs recently, so I decided to create a 
        "5-minute interview prep" website that provides simple daily practices 
        instead of overwhelming people with lengthy interview practice materials.<br/><br/>
        Staying up-to-date is always advisable.<br/><br/>
        While some companies may be decent, layoffs suck.<br/><br/>
        </div>
        <div style={{clear: 'both'}}/>
        </CurrentStyle.Item>
        <CurrentStyle.Item>
          <CurrentStyle.Headline>
            &#128236;	Share your feedback!
          </CurrentStyle.Headline>
          <br/>
          <div>
            You can send your feedback directly to the feedback@hackdaily.io. (
              <u style={{color: 'blue', cursor: 'pointer'}}
              onClick={() => copyLink('feedback', 'feedback@hackdaily.io')}>
                {copyFeedback}</u>) <br/>
            <br/>
            Feedback:
          </div>
          <CurrentStyle.TextArea onChange={changeFeedback} value={feedback}>
          </CurrentStyle.TextArea>
          <div style={{'clear': 'both'}}/>
          <CurrentStyle.SubmitButtonWrapper>
          <CurrentStyle.SubmitButton onClick={submitFeedback}>
            Submit
          </CurrentStyle.SubmitButton>
          </CurrentStyle.SubmitButtonWrapper>
          <div style={{'clear': 'both'}}/>
        </CurrentStyle.Item>
        <CurrentStyle.Item>
          <CurrentStyle.Headline>
            &#128221;	Suggest problem!
          </CurrentStyle.Headline>
          <br/>
          <div>
            You can send problem proposals directly to the problems@hackdaily.io.  (
              <u style={{color: 'blue', cursor: 'pointer'}}
              onClick={() => copyLink('problems', 'problems@hackdaily.io')}>
                {copyProblems}</u>)<br/>
            <br/>
            <label>Difficulty Level:</label>
            <select
            value={questionLevel}
            onChange={changeQuestionLevel}>
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select><br/>
            Problem:<br/>
          </div>
          <CurrentStyle.TextArea onChange={changeQuestionText} value={questionText}>
            
          </CurrentStyle.TextArea>
          <div style={{'clear': 'both'}}/>
          <CurrentStyle.SubmitButtonWrapper>
          <CurrentStyle.SubmitButton onClick={submitQuestion}>
            Submit
          </CurrentStyle.SubmitButton>
          </CurrentStyle.SubmitButtonWrapper>
          <div style={{'clear': 'both'}}/>
        </CurrentStyle.Item>
       
      </CurrentStyle.Items>
    </CurrentStyle.Wrapper>
  );
}

export default FeedbackSection;