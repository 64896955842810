import React, { useEffect, useState } from "react";
import { Share } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import {
  WebChainSectionStyle,
  MobileChainSectionStyle,
} from "./styles.js";
import {
  floorDay,
  ceilDay,
  ceilMonth,
  floorMonth,
  getPreviousMonth,
  getNextMonth,
  trimDate,
  getLaunchDate
} from './dateUtil';

var selfUrl;
if(process.env.NODE_ENV === "production") {
  selfUrl = 'https://hackdaily.herokuapp.com/';
} else if(process.env.NODE_ENV === "development") {
  selfUrl = 'http://localhost:3000/';
}


function HistorySection(props) {
  const [chainData, setChainData] = useState(null);
  const [monthText, setMonthText] = useState(null);
  const [pageDate, setPageDate] = useState(null);
  const [oldMonth, setOldMonth] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  var CurrentStyle;
  if(props.isMobile) {
    CurrentStyle = MobileChainSectionStyle;
  } else {
    CurrentStyle = WebChainSectionStyle;
  }
  async function fetchData(urlToFetch) {
    const params = Object.fromEntries(
      new URLSearchParams(urlToFetch.split('?').pop()).entries());
    const simpleMonthList = ['January','February','March','April','May',
      'June','July','August','September','October','November','December'];
    const currentDate = new Date();
    var trimmedDay, dateParam, currentMonth;
    if(params.date) {
      trimmedDay = params.date;
      dateParam = new Date(params.date);
      if(floorDay(currentDate).getTime() < ceilDay(dateParam).getTime()) {
        navigate(`/history`);
      }
      currentMonth = false;
    } else {
      trimmedDay = trimDate(currentDate);
      dateParam = currentDate;
      currentMonth = true;
    }
    if(trimmedDay == pageDate) {
      return;
    }
    fetch(`/api/get_chain_data?current_date=${trimmedDay}&current_month=${currentMonth}`)
      .then(response => response.json())
      .then(response => {
        if(response.success) {
          if(dateParam.getFullYear() == currentDate.getFullYear() &&
            dateParam.getMonth() == currentDate.getMonth()) {
            setOldMonth(false);
          } else {
            setOldMonth(true);
          }
          setPageDate(trimmedDay);
          setMonthText(simpleMonthList[dateParam.getMonth()]);
          setChainData(response.chainData);
        } else {
          console.log(response);
        }
      })
  }
  useEffect(() => {
    fetchData(window.location.href);
  });

  function _getProblemColor(problem) {
    if(problem in CurrentStyle.ColorPalatte) {
      return CurrentStyle.ColorPalatte[problem];
    }
    if(!problem || !props.profile) {
      return CurrentStyle.ColorPalatte.none;
    }
    const types = ['problems_solved', 'problems_missed',
      'late_problems_solved', 'late_problems_missed'];
    for(var type of types) {
      if(problem.problem_id in props.profile[type]) {
        return CurrentStyle.ColorPalatte[type];
      }
    }
  }

  function openProblem(trimmedDate) {
    if(!props.profile) {
      props.setMainPopup('login');
    } else {
      if(trimmedDate == chainData[chainData.length-1].date && !oldMonth) {
        navigate(`/`);
      } else {
        navigate(`/old_problems?date=${trimmedDate}`);
      }
    }
  }

  var mainContent;
  if(chainData) {
    mainContent = [];
    for(var idx=chainData.length-1;idx>=0;idx--) {
      const [year, month, day] = chainData[idx].date.split('-');
      var dayText = `${Number(month)}/${Number(day)}/${Number(year.substring(2,4))}`;
      if(Number(idx) == Number(chainData.length-1) && !oldMonth) {
        dayText = `Today's Problems`;
      }
      const problem = chainData[idx].problems;
      const date = chainData[idx].date;
      mainContent.push(<tr key={idx}>
        <CurrentStyle.DayCell>{dayText}</CurrentStyle.DayCell>
        {problem.easy ?
          <CurrentStyle.ProblemCell
            style={{'background': _getProblemColor(problem.easy)}}
            onClick={() => openProblem(date)}/>:null}
        {problem.medium ?
          <CurrentStyle.ProblemCell
            style={{'background': _getProblemColor(problem.medium)}}
            onClick={() => openProblem(date)}/>:null}
        {problem.hard ?
          <CurrentStyle.ProblemCell
            style={{'background': _getProblemColor(problem.hard)}}
            onClick={() => openProblem(date)}/>:null}
      </tr>)
    }
  }

  const colorTexts = [
    (<React.Fragment key='info_0'>
      <CurrentStyle.ColorCell style={{
        'background': _getProblemColor('problems_solved')}}/>
      <CurrentStyle.ColorInfoCell>
        Problem solved at <br/>the day it released.
      </CurrentStyle.ColorInfoCell>
      </React.Fragment>),
    (<React.Fragment key='info_1'>
      <CurrentStyle.ColorCell style={{
        'background': _getProblemColor('problems_missed')}}/>
      <CurrentStyle.ColorInfoCell>
        Problem solved incorrectly <br/>at the day it released.
      </CurrentStyle.ColorInfoCell>
      </React.Fragment>),
    (<React.Fragment key='info_2'>
      <CurrentStyle.ColorCell style={{
        'background': _getProblemColor('late_problems_solved')}}/>
      <CurrentStyle.ColorInfoCell>
        Problem solved after <br/>one day it released.
      </CurrentStyle.ColorInfoCell>
      </React.Fragment>),
    (<React.Fragment key='info_3'>
      <CurrentStyle.ColorCell style={{
        'background': _getProblemColor('late_problems_missed')}}/>
      <CurrentStyle.ColorInfoCell>
        Problem solved incorrectly after <br/>one day it released.
      </CurrentStyle.ColorInfoCell>
      </React.Fragment>),
  ];

  function goPreviousMonth() {
    const previousMonth = getPreviousMonth(new Date(pageDate));
    const trimmedDay = trimDate(previousMonth);
    navigate(`/history?date=${trimmedDay}`, {state: {date: trimmedDay}});
  }
  function goNextMonth() {
    const currentDate = new Date();
    const nextMonth = getNextMonth(new Date(pageDate));
    if(floorMonth(currentDate).getTime() < ceilMonth(nextMonth).getTime()) {
      navigate(`/history`);
    } else {
      const trimmedDay = trimDate(nextMonth);
      navigate(`/history?date=${trimmedDay}`, {state: {date: trimmedDay}});
    }
  }
  var bottomInfo = [];
  const date = new Date(pageDate);
  if(floorMonth(getLaunchDate()) <
     ceilMonth(getPreviousMonth(date))) {
    bottomInfo.push(
      <CurrentStyle.DayNavButton
        key='previous'
        onClick={goPreviousMonth}>
        &#9664; Previous Month
      </CurrentStyle.DayNavButton>
    );
  }
  if(oldMonth) {
    bottomInfo.push(
      <CurrentStyle.DayNavButton
        key='next'
        style={{'margin-left': '10px'}}
        onClick={goNextMonth}>
        Next Month &#9654;
      </CurrentStyle.DayNavButton>);
  }
  bottomInfo = (
    <CurrentStyle.DayNavItems>
      {bottomInfo}
    </CurrentStyle.DayNavItems>);

  return (
    <CurrentStyle.Wrapper>
      <CurrentStyle.Items>
        <CurrentStyle.Item>
          <CurrentStyle.MonthText>
            &#128197;	{monthText}
          </CurrentStyle.MonthText>
          <div style={{clear: 'both'}}/>
          <br/>&#128220; Problem list of {monthText}.
          <br/>&#128071; Click rectangles to open problems.
          <div style={{'clear': 'both'}}/>
          <CurrentStyle.Table>
          <thead>
          <tr>
          <CurrentStyle.Th></CurrentStyle.Th>
          <CurrentStyle.Th style={{color: '#45ab29'}}>
            Easy
          </CurrentStyle.Th>
          <CurrentStyle.Th style={{color: '#C3B14F'}}>
            Medium
          </CurrentStyle.Th>
          <CurrentStyle.Th style={{color: '#ed2828'}}>
            Hard
          </CurrentStyle.Th>
          </tr>
          </thead>
          <tbody>
            {mainContent}
          </tbody>
          </CurrentStyle.Table>
          <br/>Use bottons at the end of this page to go <b>previous/next</b> months.
        </CurrentStyle.Item>
        <CurrentStyle.Item>
          <CurrentStyle.MonthText>
            &#128477;	Info
          </CurrentStyle.MonthText>
          <div style={{clear: 'both'}}/>
          <br/>After solving some problems, above table will fill by following colors.
          <div style={{clear: 'both'}}/><br/>
          <CurrentStyle.Table style={{fontSize: '1.0em'}}>
          <tbody>
          {!props.isMobile ? <>
          <tr>{colorTexts[0]}{colorTexts[1]}</tr>
          <tr>{colorTexts[2]}{colorTexts[3]}</tr>
          </>:<>
          <tr>{colorTexts[0]}</tr><tr>{colorTexts[1]}</tr>
          <tr>{colorTexts[2]}</tr><tr>{colorTexts[3]}</tr>
          </>}
          </tbody>
          </CurrentStyle.Table>
        </CurrentStyle.Item>
        {bottomInfo}
      </CurrentStyle.Items>
    </CurrentStyle.Wrapper>
  );
}

export default HistorySection;