import styled, { createGlobalStyle } from "styled-components";

const Navbar = {
  Wrapper: styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #FFB5B5;
  `,
  Logo: styled.h1`
    border: 1px solid gray;
    padding: 0.5rem 1rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
  `,
};

export const WebNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    padding: 1rem 3rem;
  `,
  Logo: styled(Navbar.Logo)``,
  Items: styled(Navbar.Items)``,
  Item: styled(Navbar.Item)`
    background: white;
    margin: 0px 4px;
    padding: 12px 28px;
    border-radius: 9px;
  `,
};

export const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    justify-content: center;
    bottom: 0;
    position: fixed;

    padding-top: 1rem;
    padding-right: 0;
    padding-bottom: 1rem;
    padding-left: 0;
  `,
  Items: styled(Navbar.Items)`
    flex: 1;
    justify-content: space-around;
  `,
  Item: styled(Navbar.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1em;
    color: white;
  `,
  Icon: styled.span``,
};

export const QuestionBackgroundColors = {
  correct:   '#f0fcf0',
  incorrect: '#fcf1f0',
  unsolved:  'white',
};

const QuestionStyle = {
  Item: styled.li`
    align-items: center;
    background: ${props => props.correctness == null ?
    'white' : (props.correctness ? '#f0fcf0' : '#fcf1f0')};
    padding: 12px 28px;
    border-radius: 9px;
  `,
  Level: styled.div`
    float: left;
    font-size: 1.5em;
    color: ${props => ({
      'Easy': '#45ab29',
      'Medium': '#C3B14F',
      'Hard': '#ed2828',
    }[props.difficulty])};
  `,
  Share: styled.div`
    float: right;
  `,
  Description: styled.div`
    clear: both;
    font-size: 1.0em;
  `,
  SubmitButton: styled.button`
    float: right;
    background: #FFB5B5;
    color: white;

    font-size: 1.4em;
    margin: 0;
    padding: 0.12em 0.5em;
    border: 2px solid #FFB5B5;
    border-radius: 3px;
  `,
  RadioButton: styled.input`
    clear: both;
    float: left;
    border: 2px solid #FFB5B5;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    -webkit-appearance: none;
    margin: 2px;
  `,
  SelectedRadioButton: styled.input`
    clear: both;
    float: left;
    border: 2px solid #FFB5B5;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    -webkit-appearance: none;
    margin: 2px;
    display: inline-block;
    background-color: #FFB5B5;
  `,
  CorrectRadioButton: styled.input`
    clear: both;
    float: left;
    border: 2px solid #FFB5B5;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    -webkit-appearance: none;
    margin: 2px;
    display: inline-block;
    background-color: green;
  `,
  ChoiceText: styled.div`
    clear: right;
    float: left;
    // padding-bottom: 5px;
    // padding-top: 7px;

    padding: 7px;
    margin-left: 2px;
    // background-color: black;
  `,
  CodeText: styled.div`
    clear: both;
  `,
};

export const WebQuestionStyle = {
  Item: styled(QuestionStyle.Item)`
    margin: 10px 4px;
    width: 800px;
  `,
  Level: styled(QuestionStyle.Level)`
    margin: 4px;
    margin-bottom: 4px;
  `,
  Share: styled(QuestionStyle.Share)`
    margin: 4px;
  `,
  Description: styled(QuestionStyle.Description)`
    margin: 4px;
  `,
  SubmitButton: styled(QuestionStyle.SubmitButton)``,
  RadioButton: styled(QuestionStyle.RadioButton)``,
  SelectedRadioButton: styled(QuestionStyle.SelectedRadioButton)``,
  CorrectRadioButton: styled(QuestionStyle.CorrectRadioButton)``,
  ChoiceText: styled(QuestionStyle.ChoiceText)`
    max-width: 700px;
  `,
  CodeText: styled(QuestionStyle.CodeText)``,
};

export const MobileQuestionStyle = {
  Item: styled(QuestionStyle.Item)`
    margin: 10px 0px;
    padding: 12px 16px;
  `,
  Level: styled(QuestionStyle.Level)`
    margin-bottom: 4px;
  `,
  Share: styled(QuestionStyle.Share)``,
  Description: styled(QuestionStyle.Description)``,
  SubmitButton: styled(QuestionStyle.SubmitButton)``,
  RadioButton: styled(QuestionStyle.RadioButton)``,
  SelectedRadioButton: styled(QuestionStyle.SelectedRadioButton)``,
  CorrectRadioButton: styled(QuestionStyle.CorrectRadioButton)``,
  ChoiceText: styled(QuestionStyle.ChoiceText)`
    max-width: 300px;
  `,
  CodeText: styled(QuestionStyle.CodeText)`
    font-size: ${props => props.maxLineLen < 40  ? '0.9em' : '0.8em'};
  `,
};

const HackSectionStyle = {
  Wrapper: styled.nav`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  Items: styled.ul`
    list-style: none;
    align-items: center;
  `,
  InfoItems: styled.div`
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
  `,
  InfoItem: styled.li`
    align-items: center;
    background: white;
    padding: 12px 28px;
    border-radius: 9px;  
    width: fit-content;
  `,
  NavigationItem: styled.li`
    align-items: center;
    background: white;
    border-radius: 9px;  
    width: fit-content;
    background-color: #FFB5B5;
    color: white;
  `,
  RandomDayInfo: styled.button`
    align-items: center;
    background: #FFB5B5;
    padding: 12px 28px;
    border-radius: 9px;  
    width: fit-content;
    color: white;
    font-size: 1.1em;
    font-weight: bold;
    margin: 0px;
    border: 0px;    
    border-radius: 9px;
  `,
}

export const WebHackSectionStyle = {
  Wrapper: styled(HackSectionStyle.Wrapper)`
    padding: 1rem 3rem;
  `,
  Items: styled(HackSectionStyle.Items)``,
  InfoItems: styled(HackSectionStyle.InfoItems)`
    margin-top: 10px;
  `,
  InfoItem: styled(HackSectionStyle.InfoItem)`
    margin: 0px 4px;
    padding-top: 8px;
  `,
  NavigationItem: styled(HackSectionStyle.NavigationItem)`
    margin: 0px 4px;
    padding: 12px 20px;
  `,
  RandomDayInfo: styled(HackSectionStyle.RandomDayInfo)``,
};

export const MobileHackSectionStyle = {
  Wrapper: styled(HackSectionStyle.Wrapper)`
    padding: 0rem 1rem;
    padding-bottom: 80px;
  `,
  Items: styled(HackSectionStyle.Items)`
    flex: 1;
  `,
  InfoItems: styled(HackSectionStyle.InfoItems)``,
  InfoItem: styled(HackSectionStyle.InfoItem)`
    margin: 0px 5px;
    margin-top: 10px;
    padding: 12px 12px;
    padding-top: 8px;
  `,
  NavigationItem: styled(HackSectionStyle.NavigationItem)`
    margin: 0px 5px;
    margin-top: 10px;
    padding: 12px 12px;
    text-align: justify;
  `,
  RandomDayInfo: styled(HackSectionStyle.RandomDayInfo)``,
};

const ChainSectionStyle = {
  Wrapper: styled.nav`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  Items: styled.ul`
    list-style: none;
    align-items: center;
  `,
  Item: styled.li`
    align-items: center;
    background: ${props => props.correctness == null ?
    'white' : (props.correctness ? '#f0fcf0' : '#fcf1f0')};
    padding: 12px 28px;
    border-radius: 9px;
  `,
  InfoItem: styled.li`
    align-items: center;
    background: white;
    padding: 12px 28px;
    border-radius: 9px;
    width: fit-content;
    margin: auto;
  `,
  DayNavItems: styled.div`
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    text-align: center;
  `,
  DayNavButton: styled.button`
    align-items: center;
    background: #FFB5B5;
    padding: 12px 28px;
    border-radius: 9px;  
    width: fit-content;
    color: white;
    font-size: 1.1em;
    font-weight: bold;
    margin: 0px;
    border: 0px;    
    border-radius: 9px;
  `,
  Share: styled.div`
    float: right;
  `,
  Table: styled.table`
    text-align: center;
    font-size: 1.1em;
    margin: auto;
  `,
  Th: styled.th`
    width: 110px;
  `,
  DayCell: styled.td`
    height: 35px;
  `,
  ProblemCell: styled.td`
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
  `,
  ColorCell: styled.td`
    border: 1px solid black;
    border-radius: 5px;
    width: 35px;
  `,
  ColorInfoCell: styled.td`
    width: 200px;
    text-align: left;
  `,
  ColorPalatte: {
    problems_solved: '#87ff89',
    problems_missed: '#ff9087',
    late_problems_solved: '#d9ffdf',
    late_problems_missed: '#fcd5d2',
    none: 'white',
  },
  MonthText: styled.div`
    float: left;
    font-size: 1.9em;
  `
}

export const WebChainSectionStyle = {
  Wrapper: styled(ChainSectionStyle.Wrapper)`
    padding: 1rem 3rem;
  `,
  Items: styled(ChainSectionStyle.Items)``,
  Item: styled(ChainSectionStyle.Item)`
    margin: 10px 4px;
    min-width: 800px;
  `,
  InfoItem: styled(ChainSectionStyle.InfoItem)``,
  DayNavItems: styled(ChainSectionStyle.DayNavItems)``,
  DayNavButton: styled(ChainSectionStyle.DayNavButton)``,
  Share: styled(ChainSectionStyle.Share)``,
  Table: styled(ChainSectionStyle.Table)``,
  Th: styled(ChainSectionStyle.Th)``,
  DayCell: styled(ChainSectionStyle.DayCell)``,
  ProblemCell: styled(ChainSectionStyle.ProblemCell)``,
  ColorCell: styled(ChainSectionStyle.ColorCell)``,
  ColorInfoCell: styled(ChainSectionStyle.ColorInfoCell)``,
  ColorPalatte: ChainSectionStyle.ColorPalatte,
  MonthText: styled(ChainSectionStyle.MonthText)``,
};

export const MobileChainSectionStyle = {
  Wrapper: styled(ChainSectionStyle.Wrapper)`
    padding: 0rem 1rem;
    padding-bottom: 80px;
  `,
  Items: styled(ChainSectionStyle.Items)`
    flex: 1;
  `,
  Item: styled(ChainSectionStyle.Item)`
    margin: 10px 0px;
  `,
  InfoItem: styled(ChainSectionStyle.InfoItem)`
    margin-top: 10px;
  `,
  DayNavItems: styled(ChainSectionStyle.DayNavItems)``,
  DayNavButton: styled(ChainSectionStyle.DayNavButton)`
  padding: 12px 12px;
  `,
  Share: styled(ChainSectionStyle.Share)``,
  Table: styled(ChainSectionStyle.Table)`
    padding 0px;
  `,
  Th: styled(ChainSectionStyle.Th)`
    width: 70px;
  `,
  DayCell: styled(ChainSectionStyle.DayCell)`
    margin: 0px;
    padding 0px;
    font-size: 0.9em;
  `,
  ProblemCell: styled(ChainSectionStyle.ProblemCell)``,
  ColorCell: styled(ChainSectionStyle.ColorCell)``,
  ColorInfoCell: styled(ChainSectionStyle.ColorInfoCell)`
    font-size: 0.9em;
  `,
  ColorPalatte: ChainSectionStyle.ColorPalatte,
  MonthText: styled(ChainSectionStyle.MonthText)``,
};

const FeedbackSectionStyle = {
  Wrapper: styled.nav`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  Items: styled.ul`
    list-style: none;
    align-items: center;
  `,
  Item: styled.li`
    align-items: center;
    background: white;
    padding: 12px 28px;
    border-radius: 9px;
  `,
  Headline: styled.div`
    font-size: 1.5em;
    text-align: center;
  `,
  SubmitButtonWrapper: styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
  `,
  SubmitButton: styled.button`
    background: #FFB5B5;
    color: white;

    font-size: 1.4em;
    padding: 0.12em 0.5em;
    border: 2px solid #FFB5B5;
    border-radius: 3px;
  `,
  TextArea: styled.textarea`
    outline: none;
    resize: none;
    width: 100%;
    height: 250px;
  `,
  Image: styled.img`
    float: right;
    clear: both;
    width: 40%;
    border-radius: 15px;
    margin-bottom: 10px;
  `,
}

export const WebFeedbackSectionStyle = {
  Wrapper: styled(FeedbackSectionStyle.Wrapper)`
    padding: 1rem 3rem;
  `,
  Items: styled(FeedbackSectionStyle.Items)``,
  Item: styled(FeedbackSectionStyle.Item)`
    margin: 10px 4px;
    width: 800px;
  `,
  Headline: styled(FeedbackSectionStyle.Headline)``,
  SubmitButton: styled(FeedbackSectionStyle.SubmitButton)``,
  SubmitButtonWrapper: styled(FeedbackSectionStyle.SubmitButtonWrapper)``,
  TextArea: styled(FeedbackSectionStyle.TextArea)``,
  Image: styled(FeedbackSectionStyle.Image)``,
}

export const MobileFeedbackSectionStyle = {
  Wrapper: styled(FeedbackSectionStyle.Wrapper)`
    padding: 0rem 1rem;
    padding-bottom: 80px;
  `,
  Items: styled(FeedbackSectionStyle.Items)`
    flex: 1;
  `,
  Item: styled(FeedbackSectionStyle.Item)`
    margin: 10px 0px;
  `,
  Headline: styled(FeedbackSectionStyle.Headline)``,
  SubmitButton: styled(FeedbackSectionStyle.SubmitButton)``,
  SubmitButtonWrapper: styled(FeedbackSectionStyle.SubmitButtonWrapper)``,
  TextArea: styled(FeedbackSectionStyle.TextArea)``,
  Image: styled(FeedbackSectionStyle.Image)`
    width: 100%;
  `,
}

export const PopUpStyle = {
  Modal: styled.div`
    // display: none;
    // position: fixed;
    // z-index: 1;
    // background-color: rgba(0, 0, 0, 0.25);
    // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  `,
  ModalContent: styled.div`
    // background-color: white;
    // position: absolute;
    // background-color: #fefefe;
    background-color: #F5E0E0;;
    margin: 5% auto;
    padding: 10px;
    border: 1px solid #888;
    border-radius: 9px;
    width: 50%;
    max-width: 400px;
  `,
  Image: styled.img`
    display: block;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    border-radius: 15px;
  `,
  CloseButton: styled.div`
    float: right;
    margin: 0;
    padding: 0;
  `,
  Description: styled.div`
    margin: 15px;
    text-align: center;
    font-size: 1.2em;
  `,
  SubmitButton: styled.button`
    float: right;
    background: #51C34F;
    color: white;

    font-size: 1.4em;
    margin: 0;
    margin-right: 10%;
    padding: 0.12em 0.5em;
    border: 2px solid #51C34F;
    border-radius: 3px;
  `,
  DeclineButton: styled.button`
    float: left;
    background: #FFB5B5;
    color: white;

    font-size: 1.4em;
    margin: 0;
    margin-left: 10%;
    padding: 0.12em 0.5em;
    border: 2px solid #FFB5B5;
    border-radius: 3px;
  `
}

export const WebPopUpStyle = {
  Modal: styled(PopUpStyle.Modal)``,
  ModalContent: styled(PopUpStyle.ModalContent)``,
  Image: styled(PopUpStyle.Image)``,
  CloseButton: styled(PopUpStyle.CloseButton)``,
  Description: styled(PopUpStyle.Description)``,
  SubmitButton: styled(PopUpStyle.SubmitButton)``,
  DeclineButton: styled(PopUpStyle.DeclineButton)``,
}

export const MobilePopUpStyle = {
  Modal: styled(PopUpStyle.Modal)``,
  ModalContent: styled(PopUpStyle.ModalContent)`
    width: 90%;
    margin: 15% auto;
  `,
  Image: styled(PopUpStyle.Image)``,
  CloseButton: styled(PopUpStyle.CloseButton)``,
  Description: styled(PopUpStyle.Description)``,
  SubmitButton: styled(PopUpStyle.SubmitButton)``,
  DeclineButton: styled(PopUpStyle.DeclineButton)``,
}

export const Styles = {
  Wrapper: styled.main`
    background-color: #F5E0E0;
  `,
};

export const WebStyles = {
  Wrapper: styled(Styles.Wrapper)`
    min-height: 100vh;
    height: 100%;
  `,
};

export const MobileStyles = {
  Wrapper: styled(Styles.Wrapper)``,
};

export const CSSReset = createGlobalStyle`
  *,
  *::before, 
  *::after {
    margin: 0; 
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; /*1rem = 10px*/
    box-sizing: border-box;    
  }  

  body {
    font-size: 1.4rem;
    // font-family: sans-serif;
    font-family: 'Sofia Sans', sans-serif;
  }
`;