import React, { useState } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Dashboard from './Dashboard';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App path='main'/>,
  },
  {
    path: "/old_problems",
    element: <App path='old_problems'/>,
  },
  {
    path: "/history",
    element: <App path='history'/>,
  },
  {
    path: "/feedback",
    element: <App path='feedback'/>,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
    children: [
      {
        path: "days_list",
        element: <Dashboard/>,
      },
      {
        path: "problem_list",
        element: <Dashboard/>,
      },
      {
        path: "tag_view",
        element: <Dashboard/>,
      },
      {
        path: "add_problem",
        element: <Dashboard/>,
      },
      {
        path: "edit_problem",
        element: <Dashboard/>,
      },
      {
        path: "shift_all_problems",
        element: <Dashboard/>,
      },
      {
        path: "edit_user_data",
        element: <Dashboard/>,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();