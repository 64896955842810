import React, { useEffect, useState } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Share, XSquare, ChevronLeft, ChevronRight } from "react-feather";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import HistorySection from './History';
import { MobileNavigationBar, WebNavigationBar } from './Navigation';
import FeedbackSection from './Feedback';
import { parseQuestionText, ParseQuestionText } from './parseUtil';
import {
  WebQuestionStyle, 
  MobileQuestionStyle,
  WebHackSectionStyle,
  MobileHackSectionStyle,
  WebPopUpStyle,
  MobilePopUpStyle,
  WebStyles,
  MobileStyles,
  CSSReset
} from "./styles.js";
import { trimDate, ceilDay } from './dateUtil';

var selfUrl;
var clientId;
if(process.env.NODE_ENV === "production") {
  clientId = '585480318348-rp1fe393ivfaao3midrlpjq9em6bn4hh.apps.googleusercontent.com';
  selfUrl = 'https://hackdaily.herokuapp.com/';
} else if(process.env.NODE_ENV === "development") {
  clientId = '585480318348-92g2st6fp070gnrl70r53mkjhs1pk6gu.apps.googleusercontent.com';
  selfUrl = 'http://localhost:3000/';
}

ReactGA.initialize("G-R7E444Z186");

const monthsInEnglish = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
};

async function ShareQuestion(params) {
  const { url, title, text, setCopyText } = params;
  const copyClipboard = () => {
    navigator.clipboard.writeText(url);
    setCopyText('Copied!');
    setTimeout(() => {
      setCopyText(null);
    }, 1000);
  }
  if (navigator.share === undefined) {
    copyClipboard();
    return;
  }
  var shareData = {};
  if(url) { shareData.url = url; }
  if(title) { shareData.title = title; }
  if(text) { shareData.text = text; }
  try {
    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator.share(shareData);
    } else {
      copyClipboard();
      return;
    }
  } catch (error) {
    console.error("error: ", error);
  }
}

function QuestionSection(props) {
  const [userAnswer, setUserAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [copyText, setCopyText] = useState(null);
  var CurrentStyle;
  if(props.isMobile) {
    CurrentStyle = MobileQuestionStyle;
  } else {
    CurrentStyle = WebQuestionStyle;
  }
  if(props.userAnswer !== null && props.userAnswer !== userAnswer) {
    setUserAnswer(props.userAnswer);
  }
  if(props.correctAnswer !== null && props.correctAnswer !== correctAnswer) {
    setCorrectAnswer(props.correctAnswer);
  }
  var level = props.params.level;
  level = level.charAt(0)?.toUpperCase() + level.slice(1).toLocaleLowerCase()
  var description = [];
  description = parseQuestionText({
    description: props.params.description,
    userAnswer: userAnswer,
    correctAnswer: correctAnswer,
    correctness: props.correctness,
    CurrentStyle: CurrentStyle});
    
  function updateChoice(event) {
    ReactGA.event({
      category: "Question",
      action: `choice selected`,
    });
    setUserAnswer(event.target.value);
  }
  if(props.params.question_type === 'multiple_choice') {
    if(props.correctness === null) {
      description = (
        <div onChange={updateChoice}>
          {description}
        </div>
      )
    }
  }
  function submitAnswer(event) {
    ReactGA.event({
      category: "Question",
      action: `submit answer`,
    });
    event.preventDefault();
    props.submitAnswer({
      problem_id: props.params.problem_id,
      answer: userAnswer,
    });
  }
  return (
    <CurrentStyle.Item correctness={props.correctness}>
      <CurrentStyle.Level difficulty={level}>
        {level}
      </CurrentStyle.Level>
      {props.correctness === null ?
        <></> : <>{props.correctness ? 
        <CurrentStyle.Level difficulty={'Easy'}>
          (Correct Answer)
        </CurrentStyle.Level>: 
        <CurrentStyle.Level difficulty={'Hard'}>
          (Incorrect Answer)
        </CurrentStyle.Level>}</>}
      <CurrentStyle.Share onClick={() => ShareQuestion({
        url: window.location.href,
        title: "HackDaily",
        text: "Daily 5 minute interview practice.",
        setCopyText: setCopyText
      })}>
        {copyText ? copyText : <Share size={18}/>}
      </CurrentStyle.Share>
      <form onSubmit={submitAnswer}>
      <CurrentStyle.Description>
        {description}
      </CurrentStyle.Description>
      {props.correctness === null ?
        <CurrentStyle.SubmitButton type="submit">
          Submit
        </CurrentStyle.SubmitButton> : <></>}
      </form>
      <CurrentStyle.Description/>
    </CurrentStyle.Item>
  )
}

function HackSection(props) {
  const [problems, setProblems] = useState(null);
  const [historyDate, setHistoryDate] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  var CurrentStyle;
  if(props.isMobile) {
    CurrentStyle = MobileHackSectionStyle;
  } else {
    CurrentStyle = WebHackSectionStyle;
  }
  function fecthData(pathname, urlParams) {
    if(pathname === '/') {
      if(historyDate === false) {return;}
      const trimmedDay = trimDate(new Date());
      fetch(`/api/todays_problems?current_date=${trimmedDay}`)
        .then(response => response.json())
        .then(params => {
          setHistoryDate(false);
          setProblems(params.problems)});
    } else if(pathname === '/old_problems') {
      const params = Object.fromEntries(
        new URLSearchParams(urlParams.split('?').pop()).entries());
      if(props.userAuth.userLoggedIn === null) {
        setHistoryDate(null);
        setProblems(null);
        return;
      }
      if(props.userAuth.userLoggedIn === false) {
        setHistoryDate(null);
        setProblems(null);
        props.setMainPopup('history_login');
        return;
      }
      if(historyDate === params.date) {return;}
      fetch(`/api/get_old_problems?date=${params.date}`)
        .then(response => response.json())
        .then(response => {
          setHistoryDate(params.date);
          setProblems(response.problems);})
    } else {
      console.error('Unknown endpoint');
    }
  }
  useEffect(() => {
    fecthData(location.pathname, location.search);
  });
  var questionElements = [];
  var solvedAtLeastOne = false;
  if(problems) {
    for(var problem of problems) {
      const problem_id = problem.problem_id;
      const profile = props.profile;
      var userAnswer = null;
      var correctAnswer = null;
      var correctness = null;
      if(profile) {
        if(problem_id in profile.problems_solved) {
          userAnswer = profile.problems_solved[problem_id].userAnswer;
          correctAnswer = profile.problems_solved[problem_id].correctAnswer;
          correctness = true;
        } else if(problem_id in profile.late_problems_solved) {
          userAnswer = profile.late_problems_solved[problem_id].userAnswer;
          correctAnswer = profile.late_problems_solved[problem_id].correctAnswer;
          correctness = true;
        } else if(problem_id in profile.problems_missed) {
          userAnswer = profile.problems_missed[problem_id].userAnswer;
          correctAnswer = profile.problems_missed[problem_id].correctAnswer;
          correctness = false;
        } else if(problem_id in profile.late_problems_missed) {
          userAnswer = profile.late_problems_missed[problem_id].userAnswer;
          correctAnswer = profile.late_problems_missed[problem_id].correctAnswer;
          correctness = false;
        }
      }
      if(userAnswer !== null) {
        solvedAtLeastOne = true;
      }
      questionElements.push(
        <QuestionSection
        key={problem.problem_id}
        problemId={problem.problem_id}
        params={problem}
        isMobile={props.isMobile}
        submitAnswer={props.submitAnswer}
        userAnswer={userAnswer}
        correctAnswer={correctAnswer}
        correctness={correctness}/>
      )
    }
  }

  function goPreviousDay(e) {
    var currentDate = new Date();
    if(historyDate !== false) {
      currentDate = new Date(historyDate);
      currentDate.setDate(currentDate.getDate()+1);
    }
    currentDate.setDate(currentDate.getDate()-1);
    currentDate = trimDate(currentDate);
    if(location.pathname === '/') {
      navigate(`old_problems?date=${currentDate}`);
    } else {
      navigate(`?date=${currentDate}`);
    }
  }
  function goNextDay(e) {
    var todayDate = new Date();
    var currentDate = new Date();
    if(historyDate !== false) {
      currentDate = new Date(historyDate);
      currentDate.setDate(currentDate.getDate()+1);
    }
    currentDate.setDate(currentDate.getDate()+1);
    if(ceilDay(currentDate).getTime() >= ceilDay(todayDate).getTime()) {
      navigate(`/`);
    } else {
      currentDate = trimDate(currentDate);
      if(location.pathname === '/') {
        navigate(`old_problems?date=${currentDate}`);
      } else {
        navigate(`?date=${currentDate}`);
      }
    }
  }

  var headerInfo = null;
  var navigationInfo = null;
  var bottomInfo = null;
  if(historyDate === false) {
    headerInfo = (
      <CurrentStyle.InfoItems>
        <CurrentStyle.InfoItem>
          <div style={{fontSize: '1.5em', textAlign: 'center'}}>
            &#128640;	HackDaily.io
          </div>
          &#9201;Daily 5 minute interview practice.<br/>
          &#129507;Handcrafted problems from FAANG software engineer.
        </CurrentStyle.InfoItem>
      </CurrentStyle.InfoItems>
    );
    navigationInfo = (
      <CurrentStyle.InfoItems>
        <CurrentStyle.NavigationItem
          onClick={goPreviousDay}
          style={{padding: "9px"}}>
          <ChevronLeft/>
        </CurrentStyle.NavigationItem>
        <CurrentStyle.InfoItem>
          &#127919; Today's Problem List <br/>
        </CurrentStyle.InfoItem>
      </CurrentStyle.InfoItems>
    );
  } else if(historyDate !== false && historyDate !== null) {
    const [year, month, day] = historyDate.split('-');
    navigationInfo = (
      <CurrentStyle.InfoItems>
        <CurrentStyle.NavigationItem
          onClick={goPreviousDay}
          style={{padding: "9px"}}>
        <ChevronLeft/>
        </CurrentStyle.NavigationItem>
        <CurrentStyle.InfoItem>
          &#127919;	Problems of {
          `${monthsInEnglish[Number(month)]} ${Number(day)}, ${Number(year)}`} <br/>
        </CurrentStyle.InfoItem>
        <CurrentStyle.NavigationItem
          onClick={goNextDay}
          style={{padding: "9px"}}>
          <ChevronRight/>
        </CurrentStyle.NavigationItem>
      </CurrentStyle.InfoItems>
    );
  }

  if(solvedAtLeastOne) {
    function jumpToRandomDay(e) {
      fetch(`/api/get_random_day?date=${historyDate}`)
          .then(response => response.json())
          .then(params => {
            if(location.pathname === '/') {
              navigate(`old_problems?date=${params.date}`);
            } else {
              navigate(`?date=${params.date}`);
            }
          })
    }
    bottomInfo = (
      <CurrentStyle.InfoItems>
        <CurrentStyle.RandomDayInfo onClick={jumpToRandomDay}>
          Jump to Random Day! <br/>
        </CurrentStyle.RandomDayInfo>
      </CurrentStyle.InfoItems>
    );
  }
  return (
    <CurrentStyle.Wrapper>
      <CurrentStyle.Items>
        {headerInfo}
        {navigationInfo}
        {questionElements}
        {props.isMobile ? navigationInfo : null}
      </CurrentStyle.Items>
    </CurrentStyle.Wrapper>
  )
}

function PopUp(props) {
  const [loadedImage, setLoadedImage] = useState(null);
  const navigate = useNavigate();
  var CurrentStyle;
  if(props.isMobile) {
    CurrentStyle = MobilePopUpStyle;
  } else {
    CurrentStyle = WebPopUpStyle;
  }
  var closePopup = () => {
    ReactGA.event({
      category: "Popup",
      action: `Close popup: ${props.mainPopup}`,
    });
    props.closePopup();
  };
  var mainPopupContent = null;
  if(props.mainPopup) {
    ReactGA.event({
      category: "Popup",
      action: `mainPopup: ${props.mainPopup}`,
    });
  }
  if(props.mainPopup === 'login') {
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/login.jpg" alt="image"
      onLoad={() => setLoadedImage('login')}/>
      <CurrentStyle.Description>
      <div style={{visibility: loadedImage == 'login' ? 'visible' : 'hidden'}}>
        Please login to check solutions, access all questions, and track your progress:
      </div>
      </CurrentStyle.Description>
      <CurrentStyle.Description style={{
        display: 'flex', 'justify-content': 'center'}}>
        <GoogleLogin
          onSuccess={(res) => {
            props.userAuth.onSuccess(res, (profile) => {
              props.closePopup();
            });}}
          onError={props.userAuth.onFailure}/>
      </CurrentStyle.Description>
      </>);
  } else if(props.mainPopup === 'login_with_question') {
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/login.jpg" alt="image"
      onLoad={() => setLoadedImage('login')}/>
      <CurrentStyle.Description>
      <div style={{visibility: loadedImage == 'login' ? 'visible' : 'hidden'}}>
        Please login to check solutions, access all questions, and track your progress:
      </div>
      </CurrentStyle.Description>
      <CurrentStyle.Description style={{
        display: 'flex', 'justify-content': 'center'}}>
        <GoogleLogin
          onSuccess={(res) => {
            props.userAuth.onSuccess(res, (profile) => {
              if(props.currentProblem) {
                if(props.currentProblem.problem_id in profile.problems_solved ||
                  props.currentProblem.problem_id in profile.problems_missed ||
                  props.currentProblem.problem_id in profile.late_problems_solved ||
                  props.currentProblem.problem_id in profile.late_problems_missed ||
                  !props.currentProblem.answer) {
                    props.closePopup();
                } else {
                  props.setMainPopup('are_you_sure');
                }
              } else {
                props.closePopup();
              }
            });}}
          onError={props.userAuth.onFailure}/>
      </CurrentStyle.Description>
      </>);
  } else if(props.mainPopup === 'history_login') {
    closePopup = () => {
      ReactGA.event({
        category: "Popup",
        action: `Close popup: ${props.mainPopup}`,
      });
      props.closePopup();
      navigate('/');
    };
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/history_login_2.jpg" alt="image"
      onLoad={() => setLoadedImage('history_login')}/>
      <CurrentStyle.Description>
      <div style={{visibility: loadedImage == 'history_login' ? 'visible' : 'hidden'}}>
        Please login for accessing old problems:
      </div>
      </CurrentStyle.Description>
      <CurrentStyle.Description style={{
        display: 'flex', 'justify-content': 'center'}}>
        <GoogleLogin
          onSuccess={(res) => {
            props.userAuth.onSuccess(res, () => {
              props.closePopup();
            });}}
          onError={props.userAuth.onFailure}/>
      </CurrentStyle.Description>
      </>);
  } else if(props.mainPopup === 'are_you_sure') {
    if(!props.currentProblem) {
      throw new Error('There is no current problem.');
    }
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/are_you_sure-resized.jpg" alt="image"
      onLoad={() => setLoadedImage('are_you_sure')}/>
      <div style={{visibility: loadedImage == 'are_you_sure' ? 'visible' : 'hidden'}}>
      <CurrentStyle.Description>
      You only have one chance to submit!<br/>
      Submit answer?
      </CurrentStyle.Description>
      <CurrentStyle.DeclineButton onClick={props.closePopup}>
        Think again
      </CurrentStyle.DeclineButton>
      <CurrentStyle.SubmitButton
      onClick={() => props.hardSubmitAnswer(props.currentProblem)}>
        Submit!
      </CurrentStyle.SubmitButton>
      </div>
      <div style={{clear: 'both'}}></div>
      </>);
  } else if(props.mainPopup === 'choice_not_selected') {
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/choice_not_selected.jpg" alt="image"
      onLoad={() => setLoadedImage('choice_not_selected')}/>
      <CurrentStyle.Description>
      <div style={{visibility: loadedImage == 'choice_not_selected' ? 'visible' : 'hidden'}}>
        Please select one of the options before submitting.
      </div>
      </CurrentStyle.Description>
      </>);
  } else if(props.mainPopup === 'correct_answer') {
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/correct_sign_2.jpg" alt="image"
      onLoad={() => setLoadedImage('currect_sign')}/>
      <CurrentStyle.Description>
      <div style={{visibility: loadedImage == 'currect_sign' ? 'visible' : 'hidden'}}>
        Correct answer!
      </div>
      </CurrentStyle.Description>
      </>);
  } else if(props.mainPopup === 'incorrect_answer') {
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/incorrect_sign_2.jpg" alt="image"
      onLoad={() => setLoadedImage('incorrect_sign')}/>
      <CurrentStyle.Description>
      <div style={{visibility: loadedImage == 'incorrect_sign' ? 'visible' : 'hidden'}}>
        Incorrect answer!
      </div>
      </CurrentStyle.Description>
      </>);
  } else if(props.mainPopup === 'feedback_submitted') {
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/feedback_submit.jpg" alt="image"
      onLoad={() => setLoadedImage('feedback_submit')}/>
      <CurrentStyle.Description>
      <div style={{visibility: loadedImage == 'feedback_submit' ? 'visible' : 'hidden'}}>
        Thank you for the feedback!
      </div>
      </CurrentStyle.Description>
      </>);
  } else if(props.mainPopup === 'question_suggestion_submitted') {
    mainPopupContent = (<>
      <CurrentStyle.Image
      src="/question_suggestion_submit.jpg" alt="image"
      onLoad={() => setLoadedImage('question_suggestion_submit')}/>
      <CurrentStyle.Description>
      <div style={{visibility: loadedImage == 'question_suggestion_submit' ? 'visible' : 'hidden'}}>
        Thank you for the problem suggestion!
      </div>
      </CurrentStyle.Description>
      </>);
  } else {
    console.log('unknown popup state', props.mainPopup);
  }
  return (
    <CurrentStyle.Modal>
      <CurrentStyle.ModalContent>
      <CurrentStyle.CloseButton onClick={closePopup}>
        <XSquare size={26} />
      </CurrentStyle.CloseButton>
      {mainPopupContent}
      </CurrentStyle.ModalContent>
    </CurrentStyle.Modal>);
}

function App(props) {
  const [userLoggedIn, setUserLoggedIn] = useState(null);
  const [windowDimension, setWindowDimension] = useState(null);
  const [profile, setProfile] = useState(null);
  const [mainPopup, setMainPopup] = useState(null);
  const [currentProblem, setCurrentProblem] = useState(null);

  // Check user logged in.
  useEffect(() => {
    fetch('/api/check_login')
        .then(response => response.json())
        .then((params) => {
          if(params.success) {
            setProfile(params.userProfile);
            setUserLoggedIn(true);
            return;
          }
          setUserLoggedIn(false);
        })
  }, []);

  // Google Auth
  const onSuccess = (res, returnFunc) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({googleRes: res})
    };
    fetch('/api/login', requestOptions)
        .then(response => response.json())
        .then(function (params) {
          if(params.success) {
            setProfile({
              ...params.userProfile,
              tokenId: res.tokenId});
            setUserLoggedIn(true);
            if(returnFunc) {
              returnFunc(params.userProfile);
            }
          }
        })
  };
  const onFailure = (err) => {
    console.log('failed', err);
  };
  const logOut = () => {
    fetch('/api/logout')
      .then(response => response.json())
      .then(function (params) {
        setUserLoggedIn(false);
        setProfile(null);
      });
  };
  const userAuth = {
    onSuccess: onSuccess,
    onFailure: onFailure,
    logOut: logOut,
    profile: profile,
    userLoggedIn: userLoggedIn,
  };

  // Check Mobile or Web
  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);
  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isMobile = windowDimension <= 640;

  // Answer Submission
  function submitAnswer(params) {
    setCurrentProblem(params);
    if(!params.answer) {
      setMainPopup('choice_not_selected');
    } else if(!userAuth.profile) {
      setMainPopup('login_with_question');
    } else {
      setMainPopup('are_you_sure');
    }
  }
  function hardSubmitAnswer(params) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        profile: profile,
        problem_id: params.problem_id,
        answer: params.answer,
      })
    };
    fetch('/api/submit_answer', requestOptions)
      .then(response => response.json())
      .then(function(response){
        if(response.success) {
          setCurrentProblem({
            ...params,
            correctness: response.correctness,
          });
          setProfile({
            ...response.profile,
            tokenId: profile.tokenId});
          if(response.correctness) {
            setMainPopup('correct_answer');
          } else {
            setMainPopup('incorrect_answer');
          }
        } else {
          console.log(response);
        }
      });
  }
  function closePopup(params) {
    ReactGA.event({
      category: "Popup",
      action: `Close popup: ${mainPopup}`,
    });
    setMainPopup(null);
  }
  var mainContent = null;
  if(props.path === 'history') {
    mainContent = (
      <HistorySection
          isMobile={isMobile}
          profile={profile}
          setMainPopup={setMainPopup}/>);
  } else if(props.path === 'feedback') {
    mainContent = (
      <FeedbackSection
          isMobile={isMobile}
          profile={profile}
          setMainPopup={setMainPopup}/>);
  } else {
    mainContent = (
      <HackSection
          isMobile={isMobile}
          userAuth={userAuth}
          submitAnswer={submitAnswer}
          setMainPopup={setMainPopup}
          profile={profile}/>);
  }
  return (
    <>
    <GoogleOAuthProvider clientId={clientId}>
      {windowDimension ?<>
        {isMobile ? (
        <MobileStyles.Wrapper>
        <CSSReset/>
        {mainContent}
        <MobileNavigationBar
        setMainPopup={setMainPopup}
        userAuth={userAuth}/>
        </MobileStyles.Wrapper>
        ) : (
        <WebStyles.Wrapper>
        <CSSReset/>
        <WebNavigationBar userAuth={userAuth}/>
        {mainContent}
        </WebStyles.Wrapper>
        )}
        {mainPopup ?
          <PopUp
          isMobile={isMobile}
          mainPopup={mainPopup}
          setMainPopup={setMainPopup}
          closePopup={closePopup}
          userAuth={userAuth}
          currentProblem={currentProblem}
          hardSubmitAnswer={hardSubmitAnswer} /> :
          null}
      </>:<></>}
    </GoogleOAuthProvider>
    </>
  );
}

export default App;