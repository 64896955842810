import { Home, Bookmark, Upload, User } from "react-feather";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  WebNavbar,
  MobileNavbar,
} from "./styles.js";

var selfUrl;
var clientId;
if(process.env.NODE_ENV === "production") {
  clientId = '585480318348-rp1fe393ivfaao3midrlpjq9em6bn4hh.apps.googleusercontent.com';
  selfUrl = 'https://hackdaily.herokuapp.com/';
} else if(process.env.NODE_ENV === "development") {
  clientId = '585480318348-92g2st6fp070gnrl70r53mkjhs1pk6gu.apps.googleusercontent.com';
  selfUrl = 'http://localhost:3000/';
}

ReactGA.initialize("G-R7E444Z186");

export function MobileNavigationBar(props) {
  const navigate = useNavigate();
  return (
    <MobileNavbar.Wrapper>
      <MobileNavbar.Items>
        <MobileNavbar.Item
          onClick={() => {
            ReactGA.event({
              category: "Mobile Nav",
              action: `home page`,
            });
            navigate("/")
          }}>
          <MobileNavbar.Icon>
            <Home size={26} />
          </MobileNavbar.Icon>
          Hack!
        </MobileNavbar.Item>
        <MobileNavbar.Item
          onClick={() => {
            ReactGA.event({
              category: "Mobile Nav",
              action: `history page`,
            });
            navigate("/history")}}>
          <MobileNavbar.Icon>
            <Bookmark size={26} />
          </MobileNavbar.Icon>
          History
        </MobileNavbar.Item>
        <MobileNavbar.Item
          onClick={() =>  {
            ReactGA.event({
              category: "Mobile Nav",
              action: `feedback page`,
            });
            navigate("/feedback")}}>
          <MobileNavbar.Icon>
            <Upload size={26} />
          </MobileNavbar.Icon>
          Feedback
        </MobileNavbar.Item>
        {/* <MobileNavbar.Item>
          <MobileNavbar.Icon>
            <Navigation size={26} />
          </MobileNavbar.Icon>
          Discord
        </MobileNavbar.Item> */}
        {props.userAuth.userLoggedIn ?
          <MobileNavbar.Item
            onClick={() => {
              ReactGA.event({
                category: "Mobile Nav",
                action: `logout`,
              });
              props.userAuth.logOut()}}>
            <MobileNavbar.Icon>
              <User size={26} />
            </MobileNavbar.Icon>
            Logout
          </MobileNavbar.Item> :
          <MobileNavbar.Item
          onClick={() => {
            ReactGA.event({
              category: "Mobile Nav",
              action: `login`,
            });
            props.setMainPopup('login')}}>
          <MobileNavbar.Icon>
            <User size={26} />
          </MobileNavbar.Icon>
          Login
        </MobileNavbar.Item>}
      </MobileNavbar.Items>
    </MobileNavbar.Wrapper>
  );
}

export function WebNavigationBar(props) {
  const navigate = useNavigate();
  return  (
    <WebNavbar.Wrapper>
      <WebNavbar.Items>
        <WebNavbar.Item
          onClick={() =>  {
            ReactGA.event({
              category: "Web Nav",
              action: `home page`,
            });
            navigate("/")}}>
          Today's Hack!
        </WebNavbar.Item>
        <WebNavbar.Item
          onClick={() =>  {
            ReactGA.event({
              category: "Web Nav",
              action: `history page`,
            });
            navigate("/history")}}>
          History
        </WebNavbar.Item>
        <WebNavbar.Item
          onClick={() =>  {
            ReactGA.event({
              category: "Web Nav",
              action: `feedback page`,
            });
            navigate("/feedback")}}>
          Feedback</WebNavbar.Item>
        {/* <WebNavbar.Item>Discord</WebNavbar.Item> */}
      </WebNavbar.Items>
      <WebNavbar.Items>
        {props.userAuth.userLoggedIn ?
          <WebNavbar.Item
            onClick={() => {
              ReactGA.event({
                category: "Web Nav",
                action: `logout`,
              });
              props.userAuth.logOut()}}>
            Logout
          </WebNavbar.Item> :
          <GoogleLogin
            onSuccess={props.userAuth.onSuccess}
            onError={props.userAuth.onFailure}/>
        }
      </WebNavbar.Items>
    </WebNavbar.Wrapper>
  );
}