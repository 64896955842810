export function trimDate(myDate) {
  var result = `${myDate.getFullYear()}`;
  if(myDate.getMonth() + 1 < 10) {
    result += `-0${myDate.getMonth() + 1}`;
  } else {
    result += `-${myDate.getMonth() + 1}`;
  }
  if(myDate.getDate() < 10) {
    result += `-0${myDate.getDate()}`;
  } else {
    result += `-${myDate.getDate()}`;
  }
  return result;
}

export function floorDay(dateObj) {
  return new Date(trimDate(dateObj) + ' 00:01');
}

export function ceilDay(dateObj) {
  return new Date(trimDate(dateObj) + ' 23:59');
}

export function floorMonth(dateObj) {
  return new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
}

export function ceilMonth(dateObj) {
  if(dateObj.getMonth() < 11) {
    return new Date(dateObj.getFullYear(), dateObj.getMonth()+1, 0);
  }
  return new Date(dateObj.getFullYear()+1, 1, 0);
}

export function getPreviousMonth(dateObj) {
  // Ignores hour and minute.
  return new Date(dateObj.getFullYear(), dateObj.getMonth(), 0);
}

export function getNextMonth(dateObj) {
  // Ignores hour and minute.
  if(dateObj.getMonth() < 10) {
    return new Date(dateObj.getFullYear(), dateObj.getMonth()+2, 0);
  }
  return new Date(dateObj.getFullYear()+1, dateObj.getMonth()-10+1, 0);
}

export function getLaunchDate() {
  const launchDate = new Date('2023-07-1');
  return launchDate;
}